import AddGreen from "./image/add_green.png";
import Arrow_left from "./image/Arrow_left.svg";
import Arrow_right from "./image/Arrow_right.svg";
import FallInLove from "./image/fall-in-love.png";
import VnPostLogo from "./image/vnpost.jpg";
import iTopLogo from "./image/itop_logo.png";
import iTopHeaderLogo from "./image/itop_white.png";
import DoopageLogo from "./image/logo_doopage.png";
import DoopageHeaderLogo from "./image/logo_doopage_white.png";
import VnPostHeaderLogo from "./image/logo_vnpost.png";
import markerIcon from "./image/marker.png";
import Multi_panel_icon from "./image/multi_panel.svg";
import Package_icon from "./image/package-icon.svg";
import Single_panel_icon from "./image/single_panel.svg";
import TopshipLogo from "./image/topship-origin.png";
import TopshipHeaderLogo from "./image/topship-white.png";
import Empty from "./image/undraw_empty.svg";
import Error from "./image/undraw_error.svg";
import Loading from "./image/undraw_loading.svg";
import AddFile from "./image/undraw_add_file.svg";
import User_icon from "./image/users-icon.svg";
import X_icon from "./image/x-circle-icon.svg";
import NoticeIcon from "./image/NoticeIcon.svg";
import VTPOST from "./image/vtpost.jpeg";
import GHN from "./image/ghn.png";
import GHTK from "./image/ghtk.png";
import Premium from "./image/premium.svg";
import TatisaleLogo from "./image/tatisale-origin.png";
import TatisaleHeaderLogo from "./image/tatisale-white.png";
import ATPCareLogo from "./image/atpcare-origin.png";
import ATPCareHeaderLogo from "./image/atpcare-white.png";
import VietnamFlag from "./image/vietnam-flag.png";
import NoDeliveryIcon from "./image/no-delivery-truck.svg";
import GuideNotif from "./image/guide_notif.png";
import ReactionLove from "./image/reaction-love.png";
import ReactionHaha from "./image/reaction-haha.png";
import ReactionWow from "./image/reaction-wow.png";
import ReactionSad from "./image/reaction-sad.png";
import ReactionAngry from "./image/reaction-angry.png";
import ReactionLike from "./image/reaction-like.png";
import ReactionDislike from "./image/reaction-dislike.png";
import FacebookIcon from "./image/facebook.svg";
import PersonalFacebookIcon from "./image/fb_profile_icon.svg";
import GroupFacebookIcon from "./image/group_image.svg";
import ZaloOAIcon from "./image/zalooa.png";
import ZaloIcon from "./image/zalo.png";
import ShopeeIcon from "./image/shopee.png";
import SendoIcon from "./image/sendo.png";
import WebsiteIcon from "./image/website.png";
import InstagramIcon from "./image/instagram_image.svg";
import EmailIcon from "./image/email.png";
import MomaLogo from "./image/moma-origin.png";
import MomaHeaderLogo from "./image/moma-white.png";
import SendOutline from "./image/send-outline.svg";
import SendFilled from "./image/send-filled.svg";
import KidofaLogo from "./image/kidofa-origin.png";
import KidofaHeaderLogo from "./image/kidofa-white.png";
import BasketIcon from "./image/basket.svg";
import CNVInboxLogo from "./image/cnvinbox-origin.png";
import CNVInboxHeaderLogo from "./image/cnvinbox-white.png";
import NegativeSentiment from "./image/negative.svg";
import NeutralSentiment from "./image/neutral.svg";
import PositiveSentiment from "./image/positive.svg";
import PbxIcon from "./image/pbx.svg";
import TiktokIcon from "./image/tiktok.png";
import TiktokBusinessIcon from "./image/tiktok.png";
import TelegramIcon from "./image/telegram.svg";
import KiotVietIcon from "./image/kiot_viet.png";
import AgentIcon from "./image/agent.svg";
import AppleBusinessIcon from "./image/apple_business.png";
import GoogleBusinessIcon from "./image/google_business.png";
import ManualIcon from "./image/manual.svg";
import UnknownIcon from "./image/unknown.svg";

export default {
    markerIcon,
    VTPOST,
    DoopageLogo,
    Package_icon,
    User_icon,
    X_icon,
    DoopageHeaderLogo,
    Multi_panel_icon,
    Single_panel_icon,
    Arrow_left,
    Arrow_right,
    Empty,
    Loading,
    AddFile,
    Error,
    FallInLove,
    AddGreen,
    VnPostHeaderLogo,
    TopshipHeaderLogo,
    TopshipLogo,
    iTopHeaderLogo,
    iTopLogo,
    NoticeIcon,
    VnPostLogo,
    GHTK,
    GHN,
    Premium,
    TatisaleLogo,
    TatisaleHeaderLogo,
    ATPCareLogo,
    ATPCareHeaderLogo,
    VietnamFlag,
    NoDeliveryIcon,
    GuideNotif,
    ReactionAngry,
    ReactionHaha,
    ReactionLove,
    ReactionDislike,
    ReactionSad,
    ReactionWow,
    ReactionLike,
    FacebookIcon,
    PersonalFacebookIcon,
    GroupFacebookIcon,
    ZaloIcon,
    ZaloOAIcon,
    ShopeeIcon,
    SendoIcon,
    WebsiteIcon,
    EmailIcon,
    MomaLogo,
    MomaHeaderLogo,
    SendFilled,
    SendOutline,
    KidofaLogo,
    KidofaHeaderLogo,
    BasketIcon,
    CNVInboxLogo,
    CNVInboxHeaderLogo,
    NegativeSentiment,
    NeutralSentiment,
    PositiveSentiment,
    InstagramIcon,
    PbxIcon,
    TiktokIcon,
    TiktokBusinessIcon,
    TelegramIcon,
    KiotVietIcon,
    AgentIcon,
    AppleBusinessIcon,
    GoogleBusinessIcon,
    ManualIcon,
    UnknownIcon,
};
